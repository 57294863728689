import Articlegroup from "./Articlegroup";
import Article from "./Article";
import OptionGroup from "./OptionGroup";
import Campaign from "../campaigns/Campaign";
import store from "../../redux/store";

export function getArticlegroupById(
  articlegroupsMap: PartialRecord<string, Articlegroup>,
  articlegroupId: string
): Articlegroup | null {
  return articlegroupsMap[articlegroupId] ?? null;
}

export function getArticlegroupsById(
  articlegroupsMap: PartialRecord<string, Articlegroup>,
  articlegroupIds: string[]
): Articlegroup[] {
  const articlegroups: Articlegroup[] = [];
  articlegroupIds.forEach((articlegroupId) => {
    const articlegroup = getArticlegroupById(articlegroupsMap, articlegroupId);
    if (articlegroup) {
      articlegroups.push(articlegroup);
    }
  });
  return articlegroups;
}

export function getArticleById(
  articlesMap: PartialRecord<string, Article>,
  articleId: string | number
): Article | null {
  return articlesMap[articleId] ?? null;
}

export function getArticleByHash(
  articleHashNamesMap: PartialRecord<string, Article>,
  hashName: string
): Article | null {
  return articleHashNamesMap[hashName] ?? null;
}

export function getArticlesById(
  articlesMap: PartialRecord<string, Article>,
  articleIds: string[] | number[]
): Article[] {
  const articles: Article[] = [];
  articleIds.forEach((articleId) => {
    const article = getArticleById(articlesMap, articleId);
    if (article) {
      articles.push(article);
    }
  });
  return articles;
}

export function getArticlegroupsBySupergroupId(
  articlegroupsMap: PartialRecord<string, Articlegroup>,
  articlegroupChildrenMap: PartialRecord<string, string[]>,
  supergroupId: string
): Articlegroup[] {
  return (articlegroupChildrenMap[supergroupId] ?? [])
    .map((articlegroupId) => articlegroupsMap[articlegroupId])
    .filter((a): a is Articlegroup => Boolean(a))
    .filter(
      (a) =>
        a.parentId == null ||
        !store.getState().global.salesarea.custom_data?.appv5_use_articlegroups_hierarchically?.enabled
    ); // Filter not existing articlegroups
}

export function getArticlesByArticlegroupId(
  articlesMap: PartialRecord<string, Article>,
  articlegroupArticlesMap: PartialRecord<string, string[]>,
  articlegroupId: string
): Article[] {
  return getArticlesByArticlegroupIds(articlesMap, articlegroupArticlesMap, [articlegroupId]);
}

export function getArticlesByArticlegroupIds(
  articlesMap: PartialRecord<string, Article>,
  articlegroupArticlesMap: PartialRecord<string, string[]>,
  articlegroupIds: string[]
): Article[] {
  return articlegroupIds.reduce((articles: Article[], articlegroupId) => {
    articlegroupArticlesMap[articlegroupId]?.forEach((articleId) => {
      if (articlesMap[articleId]) {
        articles.push(articlesMap[articleId]);
      }
    });
    return articles;
  }, []);
}

export function getCampaignsByArticleId(
  campaignsMap: PartialRecord<string, Campaign>,
  articleCampaignsMap: PartialRecord<string, string[]>,
  articleId: string
): Campaign[] {
  return (articleCampaignsMap[articleId] ?? [])
    .map((campaignId) => {
      return campaignsMap[campaignId];
    })
    .filter((campaign): campaign is Campaign => Boolean(campaign));
}

export function getOptionGroupsByArticleId(
  articlesMap: PartialRecord<string, Article>,
  optionGroupsMap: PartialRecord<string, OptionGroup>,
  articleId: string
): OptionGroup[] {
  return (
    (getArticleById(articlesMap, articleId)
      ?.optionGroupIds?.map((optionGroupId) => optionGroupsMap[optionGroupId] ?? null)
      .filter((optionGroup) => optionGroup) as OptionGroup[]) ?? []
  );
}
