import { createSelector } from "@reduxjs/toolkit";
import { ArticleAddedToShoppingCartRule } from "../../../automation/Rule.ts";
import { RootState } from "../../store.tsx";
import { getTranslations } from "../../../api/parseApis/useParseApiArticle.ts";
import { parseCustomizableTexts } from "../selectCustomizableTexts.ts";
import { selectTimeSchedules } from "../../../../domains/timeSchedules/selectors/selectTimeSchedules.ts";
import { getNow } from "../../../models/JamezzHours.ts";
import { getDay, isAfter, setHours, setMinutes, setSeconds } from "date-fns";
import { weekDaysDateFns } from "../../../../../types/shared/TimeSchedule.ts";
import { FlowResponse, TimeScheduleResponse } from "../../api/menuDataApi.ts";

export const selectArticleAddedToShoppingCartRules = createSelector(
  [(state: RootState) => state.menuData.products, (state: RootState) => state.menuData.upsells, selectTimeSchedules],
  (products, upsells, timeSchedules) => {
    const articleAddedToShoppingCartRules: ArticleAddedToShoppingCartRule[] = [];

    products.forEach((apiArticle) => {
      const articleId = apiArticle.id;

      let translations: PartialRecord<string, PartialRecord<string, string>> = {};
      if (apiArticle.translations) {
        translations = JSON.parse(apiArticle.translations);
      }

      if (apiArticle.freeOptions) {
        parseFreeOptions(
          articleId,
          apiArticle.freeOptions,
          apiArticle.freeOptionsTitle ?? "",
          getTranslations(translations, "freeOptionsTitle"),
          articleAddedToShoppingCartRules
        );
      }
      if (apiArticle.freeOptions2) {
        parseFreeOptions(
          articleId,
          apiArticle.freeOptions2,
          apiArticle.freeOptionsTitle2 ?? "",
          getTranslations(translations, "freeOptionsTitle2"),
          articleAddedToShoppingCartRules
        );
      }
      if (apiArticle.freeOptions3) {
        parseFreeOptions(
          articleId,
          apiArticle.freeOptions3,
          apiArticle.freeOptionsTitle3 ?? "",
          getTranslations(translations, "freeOptionsTitle3"),
          articleAddedToShoppingCartRules
        );
      }
    });

    for (const upsell of upsells) {
      if (isValidUpsellVariant(upsell.upsell_variant)) {
        for (const flow of upsell.flows) {
          for (const page of flow.pages) {
            if (upsell.upsell_variant == "upsell") {
              articleAddedToShoppingCartRules.push({
                trigger: {
                  articleIds: upsell.trigger_products.map((p) => String(p.id)),
                  menuIds: upsell.trigger_menus.map((m) => String(m.id)),
                },
                action: {
                  discriminator: "ShowArticleDialogAction",
                  articleIds: page.products.map((product) => String(product.id)),
                  title: page.name[0]?.text ?? "",
                  titleTranslations: parseCustomizableTexts(page.name),
                  descriptionTranslations: parseCustomizableTexts(page.description),
                  upsellId: upsell.id,
                  upsellVariant: "upsell",
                },
                conditions: [
                  {
                    check: checkConditions(timeSchedules, flow),
                  },
                ],
              });
            } else if (upsell.upsell_variant == "upscale") {
              const products = [...page.products].sort((a, b) => a.pivot.sort_key - b.pivot.sort_key);
              for (let i = 0; i < products.length - 1; i++) {
                articleAddedToShoppingCartRules.push({
                  trigger: {
                    articleIds: [String(products[i].id)],
                    menuIds: [],
                  },
                  action: {
                    discriminator: "ShowArticleDialogAction",
                    articleIds: products.slice(i).map((product) => String(product.id)),
                    title: page.name[0]?.text ?? "",
                    titleTranslations: parseCustomizableTexts(page.name),
                    descriptionTranslations: parseCustomizableTexts(page.description),
                    upsellId: upsell.id,
                    upsellVariant: "upscale",
                  },
                  conditions: [
                    {
                      check: checkConditions(timeSchedules, flow),
                    },
                  ],
                });
              }
            }
          }
        }
      }
    }

    return articleAddedToShoppingCartRules;
  }
);

function parseFreeOptions(
  articleId: string,
  freeOptions: string,
  freeOptionsTitle: string,
  translationsTitle: PartialRecord<string, string>,
  articleAddedToShoppingCartRules: ArticleAddedToShoppingCartRule[]
) {
  if (freeOptions === "[]") {
    return;
  }

  const rule: ArticleAddedToShoppingCartRule = {
    trigger: { articleIds: [articleId] },
    action: {
      discriminator: "ShowArticleDialogAction",
      articleIds: JSON.parse(freeOptions),
      title: freeOptionsTitle,
      titleTranslations: translationsTitle,
      upsellVariant: "upsell",
    },
    conditions: [],
  };

  articleAddedToShoppingCartRules.push(rule);

  return rule;
}

function isValidUpsellVariant(value: unknown): value is "upsell" | "upscale" {
  return value === "upsell" || value === "upscale";
}

function checkConditions(timeSchedules: Record<string, TimeScheduleResponse>, flow: FlowResponse) {
  return (): boolean => {
    if (flow.time_schedule?.[0] == null) {
      return true;
    }
    const timeSchedule = timeSchedules[flow.time_schedule?.[0].id];
    if (timeSchedule == null) {
      return false;
    }

    const timeSpansOfDay = timeSchedule.opening_hours.rawData[weekDaysDateFns[getDay(getNow())]];
    return timeSpansOfDay.some((timeSpan) => {
      const [startTime, endTime] = timeSpan.split("-");
      const [startHours, startMinutes, startSeconds = "0"] = startTime.split(":");
      const [endHours, endMinutes, endSeconds = "0"] = endTime.split(":");
      const startDate = setSeconds(
        setMinutes(setHours(getNow(), Number(startHours)), Number(startMinutes)),
        Number(startSeconds)
      );
      const endDate = setSeconds(
        setMinutes(setHours(getNow(), Number(endHours)), Number(endMinutes)),
        Number(endSeconds)
      );
      return isAfter(getNow(), startDate) && isAfter(endDate, getNow());
    });
  };
}
