import { add, addMilliseconds, sub } from "date-fns";
import store from "../redux/store.tsx";

export function getNow() {
  let date = new Date();
  const simulateTime = store.getState().dev.simulateTime;

  if (simulateTime && simulateTime.direction === "FUTURE") {
    date = add(date, simulateTime.duration);
  } else if (simulateTime && simulateTime.direction === "PAST") {
    date = sub(date, simulateTime.duration);
  }

  date = addMilliseconds(date, store.getState().global.clientTimeDifferenceWithServer);
  return date;
}
