import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "lodash";
import { selectArticlesMap } from "./selectArticlesMap";
import { selectArticlegroupsMap } from "./selectArticlegroupsMap";

export const selectArticleArticlegroupsMap = createSelector(
  [(state: RootState) => state.menuData.menukaart_products, selectArticlesMap, selectArticlegroupsMap],
  (menukaartProducts, articlesMap, articlegroupsMap): PartialRecord<string, string[]> => {
    const map: PartialRecord<string, PartialRecord<string, boolean>> = {};
    menukaartProducts
      .filter((entry) => articlesMap[entry.product_id] && articlegroupsMap[entry.menukaart_id])
      .forEach((menukaartProduct) => {
        if (!map[menukaartProduct.product_id]) {
          map[menukaartProduct.product_id] = {};
        }

        const record = map[menukaartProduct.product_id];
        if (record) {
          record[menukaartProduct.menukaart_id] = true;
        }
      });

    return _.chain(map)
      .mapValues((record) => {
        return _.keys(record);
      })
      .value();
  }
);
