import CustomField, { CustomFieldOption, CustomFieldType } from "../../../../types/shared/CustomField";
import _ from "lodash";
import { PaymentMethods, PayProviderName, ProviderOption } from "../../../../types/shared/payProviders";

type ParsedPaymentMethods = PartialRecord<
  PayProviderName | string,
  {
    [paymentMethod: string | number]: ProviderOption;
  }
>;

export function parseCustomFields(rawCustomFields: CustomField[]): CustomField[] {
  const customFieldCollection: CustomField[] = [];
  rawCustomFields.forEach((customField: CustomField) => {
    if (customField.actief === false) {
      return;
    }
    customField.type = customField.type.toLowerCase();

    switch (customField.type) {
      case CustomFieldType.Pager:
      case CustomFieldType.PagerNr:
        customFieldCollection.push(parsePager(customField));
        break;
      case CustomFieldType.OrderMode:
      case CustomFieldType.SelectButtons:
        customFieldCollection.push(parseOrderModeOrSelectButtons(customField));
        break;
      case CustomFieldType.Hidden:
        customFieldCollection.push(parseHidden(customField));
        break;
      case CustomFieldType.Select:
      case CustomFieldType.Mobilenr:
      case CustomFieldType.Text:
      case CustomFieldType.Email:
      case CustomFieldType.Country:
      case CustomFieldType.Date:
      case CustomFieldType.DateTime:
      case CustomFieldType.Time:
        customFieldCollection.push(parseCustomField(customField));
        break;
    }
  });

  return customFieldCollection;
}

export function preparePaymentData(salesareaPaymentMethods: PaymentMethods): ParsedPaymentMethods {
  const paymentMethods: ParsedPaymentMethods = {};
  salesareaPaymentMethods
    .filter((payProvider) => payProvider.active !== false)
    .forEach((payProvider) => {
      if (payProvider.providerName === "PAYNL") {
        if (!paymentMethods["PAYNL"]) {
          paymentMethods["PAYNL"] = {};
        }
        const paynl = paymentMethods["PAYNL"];
        payProvider.providerOptions.forEach((paymentOption) => {
          paynl[paymentOption.id] = paymentOption;
        });
      } else if (payProvider.providerName === "CM") {
        if (!paymentMethods["CM"]) {
          paymentMethods["CM"] = {};
        }

        const cm = paymentMethods["CM"];
        payProvider.providerOptions.forEach((paymentOption) => {
          cm[paymentOption.id] = paymentOption;
        });
      } else if (payProvider.providerName === "CCV") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["CCV"]) {
              paymentMethods["CCV"] = {};
            }
            paymentMethods["CCV"][paymentOption] = { id: paymentOption };
          }
        });
      } else if (payProvider.providerName === "SIXPAYMENTSTIM") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["SIXPAYMENTSTIM"]) {
              paymentMethods["SIXPAYMENTSTIM"] = {};
            }
            paymentMethods["SIXPAYMENTSTIM"][paymentOption] = { id: paymentOption };
          }
        });
      } else if (payProvider.providerName === "ADYEN") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["ADYEN"]) {
              paymentMethods["ADYEN"] = {};
            }
            paymentMethods["ADYEN"][paymentOption.id] = {
              label: "PIN",
              payProvider: "ADYEN",
              payMethod: "PIN",
              ...paymentOption,
            };
          }
        });
      } else if (payProvider.providerName === "WORLDLINE") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["WORLDLINE"]) {
              paymentMethods["WORLDLINE"] = {};
            }
            paymentMethods["WORLDLINE"][paymentOption] = {
              id: "WORLDLINE",
              label: "PIN",
              payProvider: "WORLDLINE",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "MOLLIE") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["MOLLIE"]) {
              paymentMethods["MOLLIE"] = {};
            }
            paymentMethods["MOLLIE"][paymentOption.id] = {
              id: "MOLLIE",
              label: "PIN",
              payProvider: "MOLLIE",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "WESTPAY") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["WESTPAY"]) {
              paymentMethods["WESTPAY"] = {};
            }
            paymentMethods["WESTPAY"][paymentOption] = {
              id: "WESTPAY",
              label: "PIN",
              payProvider: "WESTPAY",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "VERIFONE_FR") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["VERIFONE_FR"]) {
              paymentMethods["VERIFONE_FR"] = {};
            }
            paymentMethods["VERIFONE_FR"][paymentOption] = {
              id: "VERIFONE_FR",
              label: "PIN",
              payProvider: "VERIFONE_FR",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "ZVT") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["ZVT"]) {
              paymentMethods["ZVT"] = {};
            }
            paymentMethods["ZVT"][paymentOption] = {
              id: "ZVT",
              label: "PIN",
              payProvider: "ZVT",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "CIKAM") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["CIKAM"]) {
              paymentMethods["CIKAM"] = {};
            }
            paymentMethods["CIKAM"][paymentOption] = {
              id: "CIKAM",
              label: "PIN",
              payProvider: "CIKAM",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "DOJO") {
        payProvider.providerOptions.forEach((paymentOption) => {
          if (paymentOption) {
            if (!paymentMethods["DOJO"]) {
              paymentMethods["DOJO"] = {};
            }
            paymentMethods["DOJO"][paymentOption] = {
              id: "DOJO",
              label: "PIN",
              payProvider: "DOJO",
              payMethod: "PIN",
            };
          }
        });
      } else if (payProvider.providerName === "SAFERPAY") {
        if (!paymentMethods["SAFERPAY"]) {
          paymentMethods["SAFERPAY"] = {};
        }
        paymentMethods["SAFERPAY"]["PIN"] = {
          id: "PIN",
          label: "PIN",
          payProvider: "SAFERPAY",
          payMethod: "PIN",
        };
      }
    });

  return paymentMethods;
}

export function parseCustomField(data: CustomField): CustomField {
  const label = (data.descr && data.descr.length > 45) || data.descr == null || data.label ? data.label : data.descr;
  const description =
    (data.descr && data.descr.length > 45) || data.descr == null || data.label ? data.descr : undefined;

  const customField: CustomField = {
    name: data.name,
    stepperLabel: data.stepperLabel,
    type: data.type,
    pattern: data.pattern,
    defaultValue: data.defaultValue ?? "",
    required: data.required ?? true,
    styles: data.styles,
    disabled: !!data.disabled,
    maxlength: Number(data.maxlength),
    translationOverwrite: data.translationOverwrite,
    rows: data.rows,
    backgroundImage: data.backgroundimage ?? null,
    media_id: data.media_id,
    label: label,
    remember: data.remember,
    explanation: _.isObject(data.explanation)
      ? _.mapKeys(data.explanation, (v, k) => k.toLowerCase())
      : data.explanation,
    description: description,
    customfieldType:
      data.customfield_type === "payment_only"
        ? "payment_only"
        : data.customfield_type === "order_only"
          ? "order_only"
          : undefined,
    activeRule: data.activeRule,
  };

  if (data.options?.[0]) {
    if (typeof data.options[0] == "string") {
      // data.options;
      customField.options = data.options.map((option) => {
        if (typeof option == "string") {
          const optionMapped: CustomFieldOption = { id: option, label: option };
          return optionMapped;
        } else {
          return option;
        }
      });
    } else {
      customField.options = data.options;
    }
  }
  return customField;
}

export function parsePager(data: CustomField): CustomField {
  return {
    name: data.name,
    stepperLabel: data.stepperLabel,
    type: CustomFieldType.Pager,
    pattern: data.pattern,
    defaultValue: "",
    media_id: data.media_id,
    maxlength: Number(data.maxlength),
    translationOverwrite: data.translationOverwrite,
    required: data.required ?? true,
    styles: data.styles,
    disabled: !!data.disabled,
    label: data.descr,
    remember: data.remember,
    explanation: _.isObject(data.explanation)
      ? _.mapKeys(data.explanation, (v, k) => k.toLowerCase())
      : data.explanation,
    customfieldType:
      data.customfield_type === "payment_only"
        ? "payment_only"
        : data.customfield_type === "order_only"
          ? "order_only"
          : undefined,
    activeRule: data.activeRule,
  };
}

export function parseHidden(data: CustomField): CustomField {
  return {
    name: data.name,
    type: CustomFieldType.Hidden,
    pattern: data.pattern,
    defaultValue: data.value,
    required: data.required ?? true,
    styles: data.styles,
    disabled: false,
    label: data.descr,
    remember: data.remember,
    value: data.value,
    customfieldType:
      data.customfield_type === "payment_only"
        ? "payment_only"
        : data.customfield_type === "order_only"
          ? "order_only"
          : undefined,
    activeRule: data.activeRule,
  };
}

export function parseOrderModeOrSelectButtons(data: CustomField): CustomField {
  if (data.options) {
    const index = data.options.findIndex((option) => (option.vatGroup ?? option.id) === (data.defaultValue ?? ""));
    if (index < 0) {
      data.defaultValue = "";
    }
  }
  return {
    name: data.name,
    stepperLabel: data.stepperLabel,
    type: data.type,
    pattern: data.pattern,
    defaultValue: data.defaultValue ?? "",
    required: data.required ?? true,
    styles: data.styles,
    disabled: !!data.disabled,
    media_id: data.media_id,
    hide: data.hide,
    label: data.descr ?? data.label,
    remember: data.remember,
    explanation: _.isObject(data.explanation)
      ? _.mapKeys(data.explanation, (v, k) => k.toLowerCase())
      : data.explanation,
    options: data.options?.map((option) => {
      return {
        id: option.vatGroup ?? option.id,
        label: option.originalDescr ?? option.descr ?? option.label,
        customHtml: option.customHtml,
        vatGroup: option.vatGroup,
        salesAreaPriceLineId: option.salesAreaPriceLineId,
        webcode: option.webcode,
        translationOverwrite: option.translationOverwrite,
        customFields: option.customFields ? parseCustomFields(option.customFields) : undefined,
        default: option.default ?? undefined,
      };
    }),
    customfieldType:
      data.customfield_type === "payment_only"
        ? "payment_only"
        : data.customfield_type === "order_only"
          ? "order_only"
          : undefined,
    activeRule: data.activeRule,
  };
}

function parsePaymentMethod(data: CustomField, options: CustomFieldOption[]): CustomField {
  if (data.options) {
    const index = data.options.findIndex((option) => (option.vatGroup ?? option.id) === (data.defaultValue ?? ""));
    if (index < 0) {
      data.defaultValue = "";
    }
  }

  return {
    name: data.name,
    stepperLabel: data.stepperLabel,
    type: CustomFieldType.PaymentMethodButtons,
    defaultValue: data.defaultValue ?? "",
    required: data.required ?? true,
    styles: data.styles,
    disabled: !!data.disabled,
    label: data.descr ?? data.label,
    remember: data.remember,
    options: options,
    customHtml: data.customHtml,
    explanation: _.isObject(data.explanation)
      ? _.mapKeys(data.explanation, (v, k) => k.toLowerCase())
      : data.explanation,
    customfieldType:
      data.customfield_type === "payment_only"
        ? "payment_only"
        : data.customfield_type === "order_only"
          ? "order_only"
          : undefined,
    activeRule: data.activeRule,
  };
}

export function parsePaymentMethod2(
  customField: CustomField,
  paymentMethodsObject: ParsedPaymentMethods
): CustomField | undefined {
  const paymentOptions: CustomFieldOption[] = [];
  customField.options?.forEach((option) => {
    if (option.id && option.payProvider && paymentMethodsObject?.[option.payProvider]?.[option.id]) {
      paymentOptions.push({
        ...option,
        id: option.id,
        label: option.descr ?? option.label ?? paymentMethodsObject[option.payProvider]?.[option.id]?.visibleName,
        payProvider: option.payProvider,
        payMethod:
          option?.payMethod ?? option?.id ?? paymentMethodsObject[option.payProvider]?.[option?.id]?.id ?? option,
      });
    } else if (option.payProvider && option.payProvider.toLowerCase() == "contant") {
      paymentOptions.push({
        ...option,
        id: option.id,
        label: option.descr ?? option.label,
        payProvider: option.payProvider,
        payMethod: option?.payMethod ?? option?.id ?? option,
      });
    }
  });

  if (paymentOptions.length > 0) {
    return parsePaymentMethod({ ...customField }, paymentOptions);
  }
}
