import Article, { calculateArticlePrice } from "../../models/menu/Article";

export function getTranslations(object: PartialRecord<string, PartialRecord<string, string>>, translationKey: string) {
  const result: PartialRecord<string, string> = {};
  Object.keys(object).forEach((language) => {
    if (object[language]?.[translationKey]) {
      result[language] = object[language][translationKey];
    }
  });
  return result;
}

export function getDiscounts(priceKey: string, article: Article) {
  const discounts: PartialRecord<string, { discount: number }> = {};
  if (priceKey) {
    const discountPercentage = article.priceKeys?.[priceKey]?.discountPercentage;
    if (discountPercentage != null) {
      discounts["priceKeyDiscount"] = {
        ...article.priceKeys?.[priceKey],
        discount: discountPercentage * article.originalPrice,
      };

      article.discounts = discounts;

      article.price = calculateArticlePrice(article);
    } else if (article.priceKeys?.[priceKey]?.type == "fixed_amount") {
      discounts["priceKeyDiscount"] = {
        ...article.priceKeys?.[priceKey],
        discount: article.originalPrice - (article.priceKeys?.[priceKey].price ?? 0),
      };

      article.discounts = discounts;
      article.price = article.priceKeys?.[priceKey].price ?? article.price;
    }
  } else {
    if (discounts["priceKeyDiscount"]) {
      discounts["priceKeyDiscount"].discount = 0;
    }
    article.discounts = discounts;
    article.price = calculateArticlePrice(article);
  }
  return discounts;
}
