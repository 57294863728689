import { createSlice } from "@reduxjs/toolkit";
import { appInitialized } from "./globalSlice";
import store from "./store.tsx";
import { intervalToDuration, Duration } from "date-fns";
import { TableItem } from "../../../types/qr/arrangements.ts";

interface DevState {
  liveEditModeEnabled: boolean;
  disableAnalytics: boolean;
  forcedOpen: boolean;
  simulateTime?: { direction: "FUTURE" | "PAST"; duration: Duration; start: string };
  arrangementTableItems: Pick<TableItem, "apiId" | "count">[] | null;
}

const initialState: DevState = {
  liveEditModeEnabled: false,
  disableAnalytics: false,
  forcedOpen: false,
  arrangementTableItems: null,
};

export const devSlice = createSlice({
  name: "dev",
  initialState,
  reducers: {
    liveEditModeEnabled: (state) => {
      state.liveEditModeEnabled = true;
    },
    liveEditModeDisabled: (state) => {
      state.liveEditModeEnabled = false;
    },
    forcedOpen: (state) => {
      state.forcedOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appInitialized, (state) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("noanalytics") || window.location.port === "5077") {
        state.disableAnalytics = true;
      }
      if (searchParams.has("forceOpen")) {
        state.forcedOpen = true;
      }
      if (searchParams.has("dev-date")) {
        const devDate = new Date(searchParams.get("dev-date") ?? "");
        state.simulateTime = {
          start: devDate.toISOString(),
          direction: devDate.getTime() > new Date().getTime() ? "FUTURE" : "PAST",
          duration: intervalToDuration({ start: new Date(), end: devDate }),
        };
      }
      if (searchParams.has("arrangementTableItems")) {
        const uriValue = searchParams.get("arrangementTableItems")!;
        try {
          const tableItems = JSON.parse(decodeURIComponent(uriValue));
          if (Array.isArray(tableItems)) {
            console.log(`DEV: setting tableStateItems to`, tableItems);
            state.arrangementTableItems = tableItems;
          } else {
            console.warn(
              "DEV VALUE ArrangementTableState has to be of type TableItem[]. TableItem = {count: number, apiId: number}"
            );
          }
        } catch (e: any) {
          console.warn(
            `DEV VALUE ArrangementTableState is invalid: ${uriValue}. Make sure it is correctly URI encoded and is valid JSON. Original error` +
              e.toString()
          );
        }
      }
    });
  },
});

export const { liveEditModeEnabled, liveEditModeDisabled, forcedOpen } = devSlice.actions;
export default devSlice.reducer;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.enableLiveEditMode = function () {
  store.dispatch(liveEditModeEnabled());
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.disableLiveEditMode = function () {
  store.dispatch(liveEditModeDisabled());
};
