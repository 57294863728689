import { createSelector } from "@reduxjs/toolkit";
import store, { RootState } from "../store";
import Articlegroup from "../../models/menu/Articlegroup";
import _ from "lodash";

export const selectSupergroupsMap = createSelector(
  [(state: RootState) => state.menuData.menukaarts],
  (articlegroups) => {
    const supergroups: Record<string, Articlegroup> = {};

    [...articlegroups]
      .sort((a, b) => a.sortKey - b.sortKey)
      .forEach((articlegroup) => {
        if (articlegroup.blocked) {
          return;
        }
        const supergroupId = String(articlegroup.category);

        if (articlegroup.category) {
          if (supergroups[supergroupId]) {
            if (!supergroups[supergroupId].translations) {
              if (articlegroup.translations) {
                supergroups[supergroupId].translations = articlegroup.translations;
              }
            } else {
              Object.keys(supergroups[supergroupId].translations).forEach((locale) => {
                if (
                  !supergroups[supergroupId].translations[locale]?.categorie &&
                  articlegroup.translations?.[locale]?.categorie
                ) {
                  supergroups[supergroupId].translations[locale].categorie =
                    articlegroup.translations[locale].categorie;
                }
              });
            }

            supergroups[supergroupId].sortKey = Math.min(supergroups[supergroupId].sortKey, articlegroup.sortKey);

            // TODO something with translations Jhorie
          } else {
            supergroups[supergroupId] = {
              id: supergroupId,
              name: articlegroup.category,
              images: [],
              sortKey: articlegroup.sortKey,
              showMaxNumberOfItems: 0,
              description: "",
              numberOfColumns: 1,
              updatedAt: "",
              showInCategoryMenu: true,
              blocked: false,
              minWidthPerArticle: 300,
              isVisibleInJamezz: true,
              settings: null,
              disableOrdering: false,
              parentId: null,
              customizable_texts: [],
              translations: _.cloneDeep(articlegroup.translations),
              openingHours: { ...articlegroup.openingHours, timezone: store.getState().global.salesarea.timezone },
            };
          }
        }
      });

    return supergroups;
  }
);
