import { createSelector } from "@reduxjs/toolkit";
import Article from "../../models/menu/Article";
import { selectArticlesMap } from "./selectArticlesMap";

export const selectArticlesApiIdMap = createSelector([selectArticlesMap], (articlesMap) => {
  const articlesApiIdMap: PartialRecord<string, Article> = {};

  Object.keys(articlesMap).forEach((articleId) => {
    const article = articlesMap[articleId];
    if (article) {
      articlesApiIdMap[article.apiId1] = articlesMap[articleId];
    }
  });

  return articlesApiIdMap;
});
