import { startAppListening } from "../listenerMiddleware";
import isQr from "../../helpers/isQr";
import OrderArticle, { OrderArticleOrigin } from "../../models/order/OrderArticle";
import {
  orderArticlesPushedBySystem,
  orderArticlesPushedByUser,
  piggyRewardClaimed,
  sharedShoppingCartTransactionsProcessed,
} from "../shoppingCartSlice";
import { shoppingCartApi, Transaction } from "../api/shoppingCartApi";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

export function sharedShoppingCartListenerAddItem() {
  startAppListening({
    actionCreator: orderArticlesPushedByUser,
    effect: (action, { dispatch, getState }) => {
      if (isQr() && getState().shoppingCart.isSharedShoppingCartEnabled && !getState().global.salesarea.payDirect) {
        const transactions: Transaction[] = action.payload
          .filter(
            (orderArticle: OrderArticle) =>
              orderArticle.added_origin === OrderArticleOrigin.MENU ||
              orderArticle.added_origin === OrderArticleOrigin.PIGGY
          )
          .map((orderArticle: OrderArticle) => {
            const uuid = uuidv4() as string;
            return {
              uuid,
              orderArticle,
            };
          });

        dispatch(sharedShoppingCartTransactionsProcessed(transactions));
        const promise = dispatch(
          shoppingCartApi.endpoints.addTransactionsToSharedShoppingCart.initiate({
            webcode: getState().global.webcode,
            transactions: transactions,
          })
        );
        promise
          .then((response) => {
            if (response.status == "rejected") {
              dispatch(
                orderArticlesPushedBySystem(
                  _.cloneDeep(action.payload).map((orderArticle) => {
                    return { ...orderArticle, count: -orderArticle.count };
                  })
                )
              );
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            promise.unsubscribe();
          });
      }
    },
  });

  startAppListening({
    actionCreator: piggyRewardClaimed,
    effect: (action, { dispatch, getState }) => {
      if (isQr() && getState().shoppingCart.isSharedShoppingCartEnabled && !getState().global.salesarea.payDirect) {
        const transactions: Transaction[] = [
          {
            uuid: uuidv4() as string,
            orderArticle: action.payload.orderArticle,
          },
        ];

        dispatch(sharedShoppingCartTransactionsProcessed(transactions));
        dispatch(
          shoppingCartApi.endpoints.addTransactionsToSharedShoppingCart.initiate({
            webcode: getState().global.webcode,
            transactions: transactions,
          })
        );
      }
    },
  });
}
