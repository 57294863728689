import { startAppListening } from "../listenerMiddleware";
import _ from "lodash";
import OrderArticle from "../../models/order/OrderArticle";
import { AppDispatch, RootState } from "../store";
import { simphonyApi } from "../api/simphonyApi.ts";
import { selectOracleDiscountIds } from "../selectors/selectCheckCalculatorDiscounts.ts";

export function simphonyCheckCalculate(state: RootState, dispatch: AppDispatch) {
  const items: OrderArticle[] = state.shoppingCart.items;

  const discountCardNr = state.shoppingCart.discountCardNr;
  const oracleDiscountIds = selectOracleDiscountIds(state);

  return new Promise((resolve, reject) => {
    dispatch(
      simphonyApi.endpoints.checkCalculator.initiate(
        {
          items: items,
          voucherOrderItems: [],
          discountCardNr,
          oracleDiscountIds,
        },
        { fixedCacheKey: "checkCalculator-SIMPHONY" }
      )
    )
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(true);
      });
  });
}

const debouncedCheckCalculate = _.debounce(simphonyCheckCalculate, 1500);

export function simphonyCheckCalculatorListener() {
  startAppListening({
    predicate: (__, currentState, previousState) => {
      const shoppingCartChanged =
        !_.isEqual(currentState.shoppingCart.items, previousState.shoppingCart.items) ||
        __.type === "mwise/rewardClaimed" ||
        __.type === "mwise/rewardCanceled" ||
        __.type === "menu/setMenu";
      return (
        currentState.global.salesarea.check_calculate_settings.enabled &&
        __.type !== "shoppingCart/simphonyVoucherCalculationReceived" &&
        __.type !== "shoppingCart/clearShoppingCart" &&
        __.type !== "shoppingCart/orderSucceeded" &&
        shoppingCartChanged
      );
    },
    effect: (action, { dispatch, getState }) => {
      debouncedCheckCalculate(getState(), dispatch);
    },
  });
}
