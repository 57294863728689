import ArticleTraitValue from "./ArticleTraitValue";
import { MediaUrl } from "../../redux/api/menuDataApi.ts";

export default interface ArticleTrait {
  apiId: string;
  name: string;
  mediaUrls?: MediaUrl[];
  values: PartialRecord<string, ArticleTraitValue>;
  type: ArticleTraitType;
  useAsQuickFilter: boolean;
  articleField?: string;
  updatedAt: string;
}

export enum ArticleTraitType {
  SINGLE_VALUE = "SINGLE_VALUE",
  MULTIPLE_VALUES = "MULTIPLE_VALUES",
  NUMBER_RANGE = "NUMBER_RANGE",
}
